import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StepperContactComponent } from './stepper-contact/stepper-contact.component';

const routes: Routes = [
  { path: '', component: StepperContactComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
