import { Component, OnInit, OnDestroy, isDevMode } from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
  FormArray,
} from "@angular/forms";
import { ContactService } from "../contact.service";
import { Party } from "../Party";
import { ViewEncapsulation } from "@angular/core";

import * as _moment from "moment";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { DateValidator } from "../date-validator.directive";
import {
  trigger,
  transition,
  state,
  animate,
  style,
} from "@angular/animations";

@Component({
  selector: "app-stepper-contact",
  templateUrl: "./stepper-contact.component.html",
  styleUrls: ["./stepper-contact.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("openClose", [
      state(
        "open",
        style({
          // 'max-height' : '2000px',
          "min-height": "1000px",
        })
      ),
      state(
        "closed",
        style({
          // 'max-height': '0',
          "min-height": "0",
        })
      ),
      transition("closed => open", [animate("2s")]),
      transition("* => closed", [animate("1s")]),
      transition("* => open", [animate("1s")]),
      transition("open <=> closed", [animate("1s")]),
      transition("* => open", [animate("1s")]),
      transition("* => *", [animate("1s")]),
    ]),
  ],
})
export class StepperContactComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  shown = isDevMode() ? true : false;

  isLinear = !isDevMode();
  party: Party;
  error: any;
  keinProblem: boolean;
  headers: string[];
  actualPartySize = 10;
  actualBudget = 0;
  selectedExtras = [];

  extrasTypes: string[] = ["pivo", "víno", "nealko", "spirity", "limonády"];

  constructor(
    private fb: FormBuilder,
    private contactService: ContactService
  ) {}

  ngOnInit() {
    this.contactForm = this.fb.group({
      // all to an array
      contactFormArray: this.fb.array([
        // party info controls
        this.fb.group({
          partyType: [
            "",
            [
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(50),
            ],
          ],
        }),
        this.fb.group({
          partySize: [10, [Validators.required, Validators.min(10)]],
        }),
        this.fb.group({
          date: [
            _moment().add(8, "days"),
            [Validators.required, DateValidator],
          ],
        }),
        this.fb.group({
          location: ["", [Validators.required, Validators.minLength(1)]],
        }),
        this.fb.group({
          checkedExtras: this.addExtrasControll(),
          noExtrasChecked: false,
        }),
        this.fb.group({
          coffee: ["Ano", Validators.required],
        }),
        this.fb.group({
          food: ["Ano", Validators.required],
        }),
        this.fb.group({
          budget: 0,
        }),
        this.fb.group({
          moreInfo: "",
        }),
        // contact info controls
        this.fb.group({
          name: ["", [Validators.required, Validators.minLength(6)]],
          email: ["", [Validators.email, Validators.required]],
          phone: [
            "",
            [
              Validators.required,
              Validators.minLength(9),
              Validators.maxLength(16),
              Validators.pattern(/^[0-9 +]+$/),
            ],
          ],
        }),
        this.fb.group({
          personalInfoConsent: [false, [Validators.requiredTrue]],
        }),
      ]),
    });
  }

  showForm() {
    this.shown = this.submitted ? false : !this.shown;
  }

  async onSubmit() {
    await this.postData();
    this.keinProblem = this.error ? false : true;
    this.shown = false;
    this.submitted = true;
  }

  createParty() {
    const party: Party = {
      partyType: this.partyType.value,
      date: this.date.value,
      location: this.location.value,
      budget: this.budget.value > 9000 ? this.budget.value : "N/A",
      coffee: this.coffee.value,
      food: this.food.value,
      extras: this.selectedExtras,
      moreInfo: this.moreInfo.value,
      name: this.name.value,
      email: this.email.value,
      partySize: this.partySize.value,
      phone: this.phone.value,
    };
    return JSON.parse(JSON.stringify(party));
  }

  postData() {
    const newParty = this.createParty();
    console.log(newParty);
    this.contactService.postParty(newParty).subscribe(
      (data: Party) => (this.party = { ...data }),
      (error) => (this.error = error)
    );
  }

  /**
   * Returns formated partySize value, with '+' appended if partySize=1000
   */
  getNicePartySize() {
    if (this.actualPartySize === 1000) {
      return this.actualPartySize + "+";
    }
    return this.actualPartySize;
  }

  /**
   * Returns formated budget value with currency appended.
   */
  getNiceBudget() {
    if (this.actualBudget < 10000) {
      return "zatím přesně nevím";
    }
    if (this.actualBudget === 100000) {
      return this.actualBudget + "+ CZK";
    }

    return this.actualBudget + " CZK";
  }

  changePartySize(event) {
    this.actualPartySize = event.value;
  }

  changeBudgetSize(event) {
    this.actualBudget = event.value;
  }

  getNiceDate() {
    return this.date.value.format("DD. MM. YYYY");
  }

  addExtrasControll() {
    return this.fb.array(this.extrasTypes.map((ex) => this.fb.control(false)));
  }

  checkNoExtras() {
    this.selectedExtras = [];
    this.checkedExtras.controls.forEach((control) => control.setValue(false));
  }

  getSelectedExtras() {
    this.selectedExtras = [];
    if (this.noExtrasChecked) {
      this.noExtrasChecked.setValue(false);
    }
    // @ts-ignore
    this.checkedExtras.controls.forEach((control, i) => {
      if (control.value) {
        this.selectedExtras.push(this.extrasTypes[i]);
      }
    });
    console.log(this.selectedExtras);
  }

  get contactFormArray(): AbstractControl | null {
    return this.contactForm.get("contactFormArray");
  }

  get partyType() {
    return this.contactFormArray.get([0]).get("partyType");
  }

  get partySize() {
    return this.contactFormArray.get([1]).get("partySize");
  }

  get date() {
    return this.contactFormArray.get([2]).get("date");
  }

  get location() {
    return this.contactFormArray.get([3]).get("location");
  }

  get extras() {
    return this.contactFormArray.get([4]);
  }

  get checkedExtras(): FormArray {
    return this.extras.get("checkedExtras") as FormArray;
  }

  get noExtrasChecked() {
    return this.extras.get("noExtrasChecked");
  }

  get coffee() {
    return this.contactFormArray.get([5]).get("coffee");
  }

  get food() {
    return this.contactFormArray.get([6]).get("food");
  }

  get budget() {
    return this.contactFormArray.get([7]).get("budget");
  }

  get moreInfo() {
    return this.contactFormArray.get([8]).get("moreInfo");
  }

  get contactInfo() {
    return this.contactFormArray.get([9]);
  }

  get name() {
    return this.contactInfo.get("name");
  }

  get email() {
    return this.contactInfo.get("email");
  }

  get phone() {
    return this.contactInfo.get("phone");
  }

  get personalInfoConsent() {
    return this.contactFormArray.get([6]).get("personalInfoConsent");
  }

  // TODO: height and animation shit...
  // TODO: flexbox grow
  // TODO: contact errors
  // TODO: responsivness
  // TODO: do CSS properly
  // TODO: retarded date states
  // TODO: show all errors properly -> location,
  // TODO: validate after clicking on button
  // TODO: add links to return to not filled answers
  // TODO: try with the same data if failed sending
  // ! TODO: BUILD PRODUCTION PROPERLY!
  // TODO: loading animation
  // TODO: button design
  // // TODO: add "other" option to party style
  // // TODO: placeholder in place
  // // TODO: ASYNC PROPERLY -> SHOWS "DEKUJEME" BEFORE IT KNOWS WHETHER IT HAS ERRORS
}
