import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
var ɵ0 = MAT_MOMENT_DATE_FORMATS, ɵ1 = { float: 'never' };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
// TODO: add go back button
// TODO: custom radio buttons
// TODO: custom icons
// // TODO: get data
// // TODO: send data to api
// // TODO: use ng-material stepper
// TODO: show button to move to next question after touching the curent
// TODO: create summary before setBindingRoot, with links to each question
