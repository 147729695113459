import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatSliderModule} from '@angular/material/slider';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatIconModule } from '@angular/material';
import {MatStepperModule, MatCardModule} from '@angular/material';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_LABEL_GLOBAL_OPTIONS} from '@angular/material/core';


import { FormAutoResizeComponent } from './form-auto-resize/form-auto-resize.component';
import { StepperContactComponent } from './stepper-contact/stepper-contact.component';
import { DateValidatorDirective } from './date-validator.directive';

@NgModule({
  declarations: [
    AppComponent,
    FormAutoResizeComponent,
    StepperContactComponent,
    DateValidatorDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatRadioModule,
    MatSliderModule,
    MatDatepickerModule,
    MatIconModule,
    MatStepperModule,
    HttpClientModule,
    MatCardModule,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'cs'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: {float: 'never'}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


// TODO: add go back button
// TODO: custom radio buttons
// TODO: custom icons
// // TODO: get data
// // TODO: send data to api
// // TODO: use ng-material stepper
// TODO: show button to move to next question after touching the curent
// TODO: create summary before setBindingRoot, with links to each question