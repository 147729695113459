import { Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

@Directive({
  selector: '[appDateValidator]'
})
export class DateValidatorDirective {

  constructor() { }

}

/**
 * Validates whether given date isn't in the past or in the following 7 days;
 * 
 * @param control date FormControl
 * @returns 'dateIsPast' error if the date is in the past, 'needMore' error if given date is in the following 7 days, null otherwise.
 */
export function DateValidator(control: FormControl) : {[key: string] : any} | null {

  const today = moment();
  // const nextWeek = moment().add(7, 'day');
  
  if (control.value.isBefore(today, 'day')) {
    return {'dateIsPast' : {value: control.value}};
  // } else if (control.value.isSameOrBefore(nextWeek, 'day')) {
  } else if (control.value.isSame(today, 'day')) {
    return {'needMore' : {value: control.value}};
  } else {
    return null;
  }

}
