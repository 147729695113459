import { Injectable, OnDestroy, isDevMode } from "@angular/core";
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Party } from "./Party";
@Injectable({
  providedIn: "root",
})
export class ContactService {
  private petmetruAPI = isDevMode()
    ? "http://localhost:5000/petmeru-web/us-central1/petmetru/api"
    : "https://us-central1-petmeru-web.cloudfunctions.net/petmetru/api";

  constructor(private http: HttpClient) {}

  postParty(party: Party): Observable<Party> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
      params: new HttpParams().set("sendMail", "true"),
    };

    return this.http
      .post<Party>(this.petmetruAPI, party, httpOptions)
      .pipe(retry(2), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
