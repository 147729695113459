import 'hammerjs';
import { enableProdMode } from '@angular/core';
import Glide from '@glidejs/glide';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(function (err) { return console.error(err); });
var glideSettings = {
    type: 'carousel',
    startAt: 0,
    perView: 3,
    gap: 75,
    breakpoints: {
        900: {
            perView: 2,
            gap: 50
        },
        800: {
            perView: 1,
            gap: 0
        },
        600: {
            perView: 1,
            gap: 0
        }
    }
};
new Glide('.glide', glideSettings).mount();
