import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Glide from '@glidejs/glide';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

const glideSettings = {
  type: 'carousel',
  startAt: 0,
  perView: 3,
  gap: 75,
  breakpoints: {
    900: {
      perView: 2,
      gap: 50
    },
    800: {
      perView: 1,
      gap: 0
    },
    600: {
      perView: 1,
      gap: 0
    }
  }
};
new Glide('.glide', glideSettings).mount();
