import { isDevMode } from "@angular/core";
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders, } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ContactService = /** @class */ (function () {
    function ContactService(http) {
        this.http = http;
        this.petmetruAPI = isDevMode()
            ? "http://localhost:5000/petmeru-web/us-central1/petmetru/api"
            : "https://us-central1-petmeru-web.cloudfunctions.net/petmetru/api";
    }
    ContactService.prototype.postParty = function (party) {
        var httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "my-auth-token",
            }),
            params: new HttpParams().set("sendMail", "true"),
        };
        return this.http
            .post(this.petmetruAPI, party, httpOptions)
            .pipe(retry(2), catchError(this.handleError));
    };
    ContactService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error("An error occurred:", error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " + ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError("Something bad happened; please try again later.");
    };
    ContactService.ngInjectableDef = i0.defineInjectable({ factory: function ContactService_Factory() { return new ContactService(i0.inject(i1.HttpClient)); }, token: ContactService, providedIn: "root" });
    return ContactService;
}());
export { ContactService };
