import * as tslib_1 from "tslib";
import { OnInit, isDevMode } from "@angular/core";
import { FormBuilder, Validators, } from "@angular/forms";
import { ContactService } from "../contact.service";
import * as _moment from "moment";
import { DateValidator } from "../date-validator.directive";
var ɵ0 = { displayDefaultIndicatorType: false };
var StepperContactComponent = /** @class */ (function () {
    function StepperContactComponent(fb, contactService) {
        this.fb = fb;
        this.contactService = contactService;
        this.submitted = false;
        this.shown = isDevMode() ? true : false;
        this.isLinear = !isDevMode();
        this.actualPartySize = 10;
        this.actualBudget = 0;
        this.selectedExtras = [];
        this.extrasTypes = ["pivo", "víno", "nealko", "spirity", "limonády"];
    }
    StepperContactComponent.prototype.ngOnInit = function () {
        this.contactForm = this.fb.group({
            // all to an array
            contactFormArray: this.fb.array([
                // party info controls
                this.fb.group({
                    partyType: [
                        "",
                        [
                            Validators.required,
                            Validators.minLength(3),
                            Validators.maxLength(50),
                        ],
                    ],
                }),
                this.fb.group({
                    partySize: [10, [Validators.required, Validators.min(10)]],
                }),
                this.fb.group({
                    date: [
                        _moment().add(8, "days"),
                        [Validators.required, DateValidator],
                    ],
                }),
                this.fb.group({
                    location: ["", [Validators.required, Validators.minLength(1)]],
                }),
                this.fb.group({
                    checkedExtras: this.addExtrasControll(),
                    noExtrasChecked: false,
                }),
                this.fb.group({
                    coffee: ["Ano", Validators.required],
                }),
                this.fb.group({
                    food: ["Ano", Validators.required],
                }),
                this.fb.group({
                    budget: 0,
                }),
                this.fb.group({
                    moreInfo: "",
                }),
                // contact info controls
                this.fb.group({
                    name: ["", [Validators.required, Validators.minLength(6)]],
                    email: ["", [Validators.email, Validators.required]],
                    phone: [
                        "",
                        [
                            Validators.required,
                            Validators.minLength(9),
                            Validators.maxLength(16),
                            Validators.pattern(/^[0-9 +]+$/),
                        ],
                    ],
                }),
                this.fb.group({
                    personalInfoConsent: [false, [Validators.requiredTrue]],
                }),
            ]),
        });
    };
    StepperContactComponent.prototype.showForm = function () {
        this.shown = this.submitted ? false : !this.shown;
    };
    StepperContactComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.postData()];
                    case 1:
                        _a.sent();
                        this.keinProblem = this.error ? false : true;
                        this.shown = false;
                        this.submitted = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    StepperContactComponent.prototype.createParty = function () {
        var party = {
            partyType: this.partyType.value,
            date: this.date.value,
            location: this.location.value,
            budget: this.budget.value > 9000 ? this.budget.value : "N/A",
            coffee: this.coffee.value,
            food: this.food.value,
            extras: this.selectedExtras,
            moreInfo: this.moreInfo.value,
            name: this.name.value,
            email: this.email.value,
            partySize: this.partySize.value,
            phone: this.phone.value,
        };
        return JSON.parse(JSON.stringify(party));
    };
    StepperContactComponent.prototype.postData = function () {
        var _this = this;
        var newParty = this.createParty();
        console.log(newParty);
        this.contactService.postParty(newParty).subscribe(function (data) { return (_this.party = tslib_1.__assign({}, data)); }, function (error) { return (_this.error = error); });
    };
    /**
     * Returns formated partySize value, with '+' appended if partySize=1000
     */
    StepperContactComponent.prototype.getNicePartySize = function () {
        if (this.actualPartySize === 1000) {
            return this.actualPartySize + "+";
        }
        return this.actualPartySize;
    };
    /**
     * Returns formated budget value with currency appended.
     */
    StepperContactComponent.prototype.getNiceBudget = function () {
        if (this.actualBudget < 10000) {
            return "zatím přesně nevím";
        }
        if (this.actualBudget === 100000) {
            return this.actualBudget + "+ CZK";
        }
        return this.actualBudget + " CZK";
    };
    StepperContactComponent.prototype.changePartySize = function (event) {
        this.actualPartySize = event.value;
    };
    StepperContactComponent.prototype.changeBudgetSize = function (event) {
        this.actualBudget = event.value;
    };
    StepperContactComponent.prototype.getNiceDate = function () {
        return this.date.value.format("DD. MM. YYYY");
    };
    StepperContactComponent.prototype.addExtrasControll = function () {
        var _this = this;
        return this.fb.array(this.extrasTypes.map(function (ex) { return _this.fb.control(false); }));
    };
    StepperContactComponent.prototype.checkNoExtras = function () {
        this.selectedExtras = [];
        this.checkedExtras.controls.forEach(function (control) { return control.setValue(false); });
    };
    StepperContactComponent.prototype.getSelectedExtras = function () {
        var _this = this;
        this.selectedExtras = [];
        if (this.noExtrasChecked) {
            this.noExtrasChecked.setValue(false);
        }
        // @ts-ignore
        this.checkedExtras.controls.forEach(function (control, i) {
            if (control.value) {
                _this.selectedExtras.push(_this.extrasTypes[i]);
            }
        });
        console.log(this.selectedExtras);
    };
    Object.defineProperty(StepperContactComponent.prototype, "contactFormArray", {
        get: function () {
            return this.contactForm.get("contactFormArray");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "partyType", {
        get: function () {
            return this.contactFormArray.get([0]).get("partyType");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "partySize", {
        get: function () {
            return this.contactFormArray.get([1]).get("partySize");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "date", {
        get: function () {
            return this.contactFormArray.get([2]).get("date");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "location", {
        get: function () {
            return this.contactFormArray.get([3]).get("location");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "extras", {
        get: function () {
            return this.contactFormArray.get([4]);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "checkedExtras", {
        get: function () {
            return this.extras.get("checkedExtras");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "noExtrasChecked", {
        get: function () {
            return this.extras.get("noExtrasChecked");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "coffee", {
        get: function () {
            return this.contactFormArray.get([5]).get("coffee");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "food", {
        get: function () {
            return this.contactFormArray.get([6]).get("food");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "budget", {
        get: function () {
            return this.contactFormArray.get([7]).get("budget");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "moreInfo", {
        get: function () {
            return this.contactFormArray.get([8]).get("moreInfo");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "contactInfo", {
        get: function () {
            return this.contactFormArray.get([9]);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "name", {
        get: function () {
            return this.contactInfo.get("name");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "email", {
        get: function () {
            return this.contactInfo.get("email");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "phone", {
        get: function () {
            return this.contactInfo.get("phone");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StepperContactComponent.prototype, "personalInfoConsent", {
        get: function () {
            return this.contactFormArray.get([6]).get("personalInfoConsent");
        },
        enumerable: true,
        configurable: true
    });
    return StepperContactComponent;
}());
export { StepperContactComponent };
export { ɵ0 };
