<div *ngIf="submitted && error">
  <h1 class="after-post-mssg">Litujeme, vyskytl se problém!</h1>
</div>

<div *ngIf="submitted && keinProblem">
  <h1 class="after-post-mssg">Děkujeme! Brzy Vás budeme kontaktovat!</h1>
</div>

<div class="intro">
  <h2 [hidden]="submitted">Vyplňte prosím náš nezávazný formulář:</h2>
  <button
    id="open-form-button"
    *ngIf="!shown && !submitted"
    mat-raised-button
    class="form-open-button"
    (click)="showForm()"
  >
    Chci vás!
  </button>
</div>

<form
  id="party-form"
  [@openClose]="shown ? 'open' : 'closed'"
  [hidden]="!shown"
  [formGroup]="contactForm"
  (ngSubmit)="onSubmit()"
>
  <mat-vertical-stepper
    formArrayName="contactFormArray"
    [linear]="isLinear"
    #stepper
  >
    <!-- PARTY TYPE -->
    <mat-step
      state="partyType"
      formGroupName="0"
      [completed]="partyType.valid"
      [stepControl]="contactFormArray.get([0])"
    >
      <ng-template matStepLabel>Co plánujete?</ng-template>
      <div class="radio-group">
        <mat-card [ngClass]="partyType.value === 'svadba' ? 'selected' : ''">
          <label class="radio-label wedding" for="wedding">
            <i class="material-icons"> wc </i>
            <p class="radio-label-text">svatba</p>
            <input
              class="radio-input"
              type="radio"
              value="svadba"
              id="wedding"
              formControlName="partyType"
            />
          </label>
        </mat-card>

        <mat-card [ngClass]="partyType.value === 'oslava' ? 'selected' : ''">
          <label class="radio-label non-formal" for="oslava">
            <i class="material-icons">cake</i>
            <input
              class="radio-input"
              type="radio"
              value="oslava"
              id="oslava"
              formControlName="partyType"
            />
            <p class="radio-label-text">oslava</p>
          </label>
        </mat-card>
        <mat-card [ngClass]="partyType.value === 'festival' ? 'selected' : ''">
          <label class="radio-label festival" for="festival">
            <i class="fas fa-music"></i>
            <input
              class="radio-input"
              type="radio"
              value="festival"
              id="festival"
              formControlName="partyType"
            />
            <p class="radio-label-text">festival</p>
          </label>
        </mat-card>

        <mat-card
          [ngClass]="partyType.value === 'konference' ? 'selected' : ''"
        >
          <label class="radio-label non-formal" for="konference">
            <i class="fas fa-comments"></i>
            <p class="radio-label-text">konference</p>
            <input
              class="radio-input"
              type="radio"
              value="konference"
              id="konference"
              formControlName="partyType"
            />
          </label>
        </mat-card>
        <mat-card [ngClass]="partyType.value === 'firemni' ? 'selected' : ''">
          <label class="radio-label formal" for="firemni">
            <i class="fas fa-user-tie"></i>
            <p class="radio-label-text">firemní večírek</p>
            <input
              class="radio-input"
              type="radio"
              value="firemni"
              id="firemni"
              formControlName="partyType"
            />
          </label>
        </mat-card>
      </div>

      <mat-form-field>
        <input
          maxlength="50"
          matInput
          placeholder="Něco jiného:"
          formControlName="partyType"
        />
        <mat-hint align="start">krátký popis akce </mat-hint>
        <mat-hint align="end">{{ partyType.value.length }} / 50</mat-hint>
      </mat-form-field>

      <mat-error *ngIf="partyType.touched && partyType.invalid">
        Prosím uveďte, jakou formu akce chystáte.
      </mat-error>
      <!-- TODO:  if length another error -->

      <mat-error *ngIf="partyType.invalid && partyType.touched"> </mat-error>

      <!-- TODO: create group by its own, so user can add aditional info -->
      <div>
        <button
          mat-raised-button
          [disabled]="partyType.invalid"
          matStepperNext
          type="button"
        >
          OK
        </button>
      </div>
    </mat-step>

    <!-- PARTY SIZE -->
    <mat-step
      state="partySize"
      formGroupName="1"
      [completed]="partySize.valid && partySize.touched"
      [stepControl]="contactFormArray.get([1])"
    >
      <ng-template matStepLabel>Kolik hostů očekáváte?</ng-template>
      <p class="mat-step-description">Hosté: {{ getNicePartySize() }}</p>
      <mat-slider
        (input)="changePartySize($event)"
        formControlName="partySize"
        min="10"
        max="1000"
        step="10"
        value="partySize.value"
      >
      </mat-slider>
      <mat-error
        *ngIf="partySize.invalid && (partySize.touched || partySize.dirty)"
      >
        Potřebujeme více než {{ partySize.value }} osob!
      </mat-error>
      <div>
        <button
          [disabled]="partySize.invalid"
          mat-raised-button
          matStepperNext
          type="button"
        >
          OK
        </button>
      </div>
    </mat-step>

    <!-- DATE -->
    <mat-step
      state="date"
      label="Kdy se bude Vase akce konat?"
      formGroupName="2"
      [stepControl]="contactFormArray.get([2])"
      [completed]="date.valid && date.touched"
    >
      <ng-template matStepLabel>Kdy se uvidíme na baru?</ng-template>

      <mat-form-field>
        <input
          matInput
          [matDatepicker]="picker"
          placeholder="Vyberte prosím datum:"
          [formControl]="date"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <div *ngIf="date.invalid && (date.touched || date.dirty)">
        <mat-error
          *ngIf="
            date.invalid && !(date.errors.dateIsPast || date.errors.needMore)
          "
        >
          Nesprávné datum!
        </mat-error>
        <mat-error *ngIf="date.invalid && date.errors.dateIsPast">
          Cestovat časem bohužel neumíme ...
        </mat-error>
        <mat-error *ngIf="date.invalid && date.errors.needMore">
          Vážně už dnes?
        </mat-error>
      </div>
      <div>
        <button
          mat-raised-button
          [disabled]="date.invalid"
          mat-button
          matStepperNext
          type="button"
        >
          OK
        </button>
      </div>
    </mat-step>

    <!-- LOCATION -->
    <mat-step
      state="location"
      formGroupName="3"
      [stepControl]="contactFormArray.get([3])"
      [completed]="location.valid"
    >
      <ng-template matStepLabel>Kde to rozbalíme?</ng-template>
      <mat-form-field>
        <input
          matInput
          placeholder="adresa nebo lokalita"
          formControlName="location"
          required
        />
      </mat-form-field>
      <mat-error
        *ngIf="location.invalid && (location.touched || location.dirty)"
      >
        Tam asi netrefíme.
      </mat-error>
      <button
        [disabled]="location.invalid"
        mat-raised-button
        matStepperNext
        type="button"
      >
        OK
      </button>
    </mat-step>

    <!-- EXTRA -->
    <mat-step
      state="extra"
      formGroupName="4"
      [stepControl]="contactFormArray.get([4])"
      [completed]="extras.valid && extras.touched"
    >
      <ng-template matStepLabel>Co bychom měli s sebou ještě vzít?</ng-template>
      <p class="mat-step-description">
        Zde můžete zaškrtnout více možností, my Vám k tomu vytvoříme přehlednou
        cenovou nabídku.
      </p>

      <div formArrayName="checkedExtras">
        <div *ngFor="let control of checkedExtras.controls; let i = index">
          <mat-checkbox
            [formControl]="control"
            (change)="getSelectedExtras()"
            >{{ extrasTypes[i] }}</mat-checkbox
          >
        </div>
      </div>

      <div className="white-space"></div>

      <mat-checkbox
        style="padding-top: 1rem"
        formControlName="noExtrasChecked"
        class="example-margin"
        (change)="checkNoExtras()"
      >
        Mám zájem jen o koktejlový catering.
      </mat-checkbox>
    </mat-step>

    <!-- COFFEE -->
    <mat-step
      state="coffee"
      formGroupName="5"
      [stepControl]="contactFormArray.get([5])"
      [completed]="coffee.touched && coffee.valid"
    >
      <ng-template matStepLabel>
        A co espresso, cappuccino, nebo třeba cold brew?
      </ng-template>
      <p class="mat-step-description">
        Kromě cocktailů to umíme také s výběrovou kávou! Úzce spolupracujeme s
        pražírnou Rusty Nails Coffee Roasters a také s profesionálními baristy z
        našich podniků SKØG a TYPIKA.
      </p>
      <mat-radio-group class="column-radio-group" formControlName="coffee">
        <mat-radio-button value="Ano">
          Ano, zašlete mi nabídku i na kávový catering
        </mat-radio-button>
        <mat-radio-button value="Ne">
          Ne, o kávu na akci nemám zájem.
        </mat-radio-button>
      </mat-radio-group>
    </mat-step>

    <!-- FOOD -->
    <mat-step
      state="food"
      formGroupName="6"
      [stepControl]="contactFormArray.get([6])"
      [completed]="food.touched && food.valid"
    >
      <ng-template matStepLabel>
        Skvělá akce se neobejde bez jídla!
      </ng-template>
      <p class="mat-step-description">
        Pokud chcete mít organizaci Vaší akce úplně bez starostí, jste na
        správném místě! Dokážeme zařídit také skvělé jídlo! O menu se postarají
        vedoucí našich kuchyní ze SKØGU a TYPIKY. Případně naši dobří kamarádi z
        foodtrucku CRUSH STREET FOOD.
      </p>
      <mat-radio-group class="column-radio-group" formControlName="food">
        <mat-radio-button value="Ano">
          Ano, pojďme si popovídat také o jídle!
        </mat-radio-button>
        <mat-radio-button value="Ne">
          Ne, o nabídku jídla nemám zájem.
        </mat-radio-button>
      </mat-radio-group>
    </mat-step>

    <!-- BUDGET -->
    <mat-step
      state="budget"
      formGroupName="7"
      [completed]="budget.touched"
      [stepControl]="contactFormArray.get([4])"
    >
      <ng-template matStepLabel>Jaký máte rozpočet?</ng-template>
      <p class="mat-step-description">Rozpočet: {{ getNiceBudget() }}</p>
      <mat-slider
        (input)="changeBudgetSize($event)"
        formControlName="budget"
        min="9000"
        max="100000"
        step="1000"
        value="budget.value"
      >
      </mat-slider>

      <div>
        <button mat-raised-button matStepperNext type="button">OK</button>
      </div>
    </mat-step>

    <!-- MORE INFO -->
    <mat-step
      state="moreInfo"
      formGroupName="8"
      [stepControl]="contactFormArray.get([5])"
      [completed]="moreInfo.touched"
    >
      <ng-template matStepLabel>Napadá Vás ještě něco?</ng-template>
      <p class="mat-step-description">
        Zde se můžete zeptat na to, co by Vás zajímalo, nebo sdělit, co bychom
        ještě měli vedět.
      </p>
      <mat-form-field>
        <textarea
          maxlength="200"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="10"
          matInput
          placeholder=""
          formControlName="moreInfo"
        >
        </textarea>
        <mat-hint align="end">{{ moreInfo.value.length }} / 200</mat-hint>
      </mat-form-field>
      <div>
        <button mat-raised-button matStepperNext type="button">OK</button>
      </div>
    </mat-step>

    <!-- PERSONAL INFO -->
    <mat-step
      state="contact"
      formGroupName="9"
      [stepControl]="contactFormArray.get([6])"
      [completed]="contactInfo.valid"
    >
      <ng-template matStepLabel>Kontaktní údaje</ng-template>
      <mat-form-field>
        <input
          matInput
          placeholder="Jméno a příjmení"
          formControlName="name"
          required
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="E-mail:"
          formControlName="email"
          required
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Telefon:"
          formControlName="phone"
          required
        />
      </mat-form-field>
      <mat-error *ngIf="name.invalid && name.touched">
        Je to doopravdy Vaše skutečné jméno?
      </mat-error>
      <mat-error *ngIf="email.invalid && email.touched">
        Takhle teda e-mail nevypadá...
      </mat-error>
      <mat-error *ngIf="phone.invalid && phone.touched">
        Tak na tohle číslo se Vám nejspíš nedovoláme...
      </mat-error>

      <div>
        <button
          [disabled]="phone.invalid"
          mat-raised-button
          matStepperNext
          type="button"
        >
          OK
        </button>
      </div>
    </mat-step>

    <!-- SEND -->
    <mat-step
      [completed]="contactForm.valid"
      state="send"
      formGroupName="10"
      [stepControl]="contactFormArray.get([7])"
    >
      <ng-template matStepLabel>Rekapitulace</ng-template>
      <div class="recap-data">
        <h3>Vaše akce:</h3>
        <p><strong>Typ akce:</strong> {{ partyType.value }}</p>
        <p><strong>Počet lidí:</strong> {{ partySize.value }}</p>
        <p><strong>Datum:</strong> {{ getNiceDate() }}</p>
        <p><strong>Místo:</strong> {{ location.value }}</p>
        <p>
          <strong>Přinesem: </strong>
          <span *ngIf="noExtrasChecked.value"> Jen sebe a koktejly.</span>

          <span *ngIf="selectedExtras.length"
            >Koktejly
            <span *ngFor="let extra of selectedExtras"
              >, {{ extra }}
            </span> </span
          >.
        </p>
        <p>
          <strong>Kafe:</strong>
          {{ coffee.value }}
        </p>
        <p>
          <strong>Jídlo:</strong>
          {{ food.value }}
        </p>
        <p><strong>Rozpočet:</strong> {{ getNiceBudget() }}</p>
        <p *ngIf="moreInfo.value.length > 2">
          <strong>Další info:</strong> {{ moreInfo.value }}
        </p>
        <h3>Vaše kontaktní údaje:</h3>
        <p><strong>Jméno:</strong> {{ name.value }}</p>
        <p><strong>E-mail:</strong> {{ email.value }}</p>
        <p><strong>Tel.:</strong> {{ phone.value }}</p>
      </div>

      <mat-checkbox
        class="personal-info-consent"
        [value]="personalInfoConsent"
        formControlName="personalInfoConsent"
        >Souhlasím se
        <a
          class="personal-info-link"
          rel="noopener"
          href="https://firebasestorage.googleapis.com/v0/b/petmeru-web.appspot.com/o/GDPR.pdf?alt=media&token=e5897069-6499-46b4-b8cf-f2de8d5b4d8b"
          target="_blank"
          ><strong>zpracováním osobních údajů.</strong></a
        ></mat-checkbox
      >

      <div style="align-self: center">
        <button mat-raised-button [disabled]="contactForm.invalid">
          Odeslat
        </button>
      </div>
    </mat-step>

    <!-- icons overrides -->
    <!-- TODO: same icon when editing -->
    <ng-template matStepperIcon="date">
      <mat-icon class="mat-icon-big">date_range</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="location">
      <mat-icon class="mat-icon-big">place</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="partyType">
      <mat-icon class="mat-icon-big">local_bar</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="partySize">
      <mat-icon class="mat-icon-big">people</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="contact">
      <mat-icon class="mat-icon-big">contact_mail</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="send">
      <mat-icon class="mat-icon-big">mail_outline</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="moreInfo">
      <mat-icon class="mat-icon-big">chat</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="budget">
      <i class="fas fa-wallet"></i>
    </ng-template>
    <ng-template matStepperIcon="coffee">
      <mat-icon class="mat-icon-big">local_cafe</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="food">
      <mat-icon class="mat-icon-big">restaurant</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="extra">
      <i class="fas fa-beer"></i>
    </ng-template>
  </mat-vertical-stepper>
</form>
